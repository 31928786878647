import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
import { English } from "flatpickr/dist/l10n/fr.js"
import { Dutch } from "flatpickr/dist/l10n/nl.js"
require("flatpickr/dist/themes/material_green.css");

const urlang = (x) => {
   if (x == 'en') {
     return English;
   } else if (x == 'nl') {
     return Dutch;
   } else  
     return French;
    };

const initFlatpickr = () => {
  flatpickr(".datepicker", {
    "locale": urlang(document.location.href.split('/', 4)[3]),
    altInput: true,
    allowInput: true,
    altFormat: "d-m-Y"
});
}

export { initFlatpickr };