const diffDate = () => {
 
   // $('form').on('click', '.add_fields', function(event) {
    document.querySelector('form').addEventListener('click', () => {
      let c = document.querySelectorAll('.cap');
      checkdate(c);
    });
  };
  
  const checkdate = (c) =>{
    const cr = document.querySelectorAll('.cred');
    const credate = [];
    for (let i = 0 ; i < cr.length; i+=2) {
      credate.push(cr[i].value)
    };
    const capdate = [];
    for (let j = 0 ; j  < c.length; j+=2) {
      if (c[j].value !== "") {
        capdate.push(c[j].value)
      }
    };
    const alldates = credate.concat(capdate);
    const lang = (e) => {
      if (document.URL.includes('en')) {
        e.lang = ['Less than one year since ', "last recorded capitalization", "last recorded debt"];
      } else if (document.URL.includes('nl')) {
        e.lang = ['Minder dan een jaar sinds ', "laatste opgeslagen kapitalisatie", "laatste opgeslagen vordering"];
      } else {
        e.lang = ["Moins d'un an depuis le", "dernière capitalisation enregistrée", "dernière créance enregistrée"]
      };
      return e.lang
    };
c.forEach((fld) => {
  fld.addEventListener('change', (e) => {
        const sortalldates = alldates.sort().reverse().filter(dt => dt !== capdate[Number.parseInt(e.target.id.split('_')[3],10)])
        let n = 0
        let lastdate = sortalldates[0]
        do {
          lastdate = sortalldates[n]
          n++
        } while (new Date(lastdate) > new Date(e.currentTarget._flatpickr.input.value));
        const annee = lastdate.replace(lastdate.split('-')[0] ,(Number.parseInt(lastdate.split('-')[0], 10) + 1).toString())
      if (new Date(e.currentTarget._flatpickr.input.value) < new Date(annee)) {
        const typ = (e) => {
          if (capdate.includes(lastdate)) {
            e.typ = lang(e)[1];
          } else if (credate.includes(lastdate)) {
            e.typ = lang(e)[2];
          }
          return e.typ
      };
        alert(`${lang(e)[0]} ${new Date(lastdate).getDate()}-${new Date(lastdate).getMonth()+1}-${new Date(lastdate).getFullYear()} (${typ(e)})`);
      }
      e.stopImmediatePropagation();
      });
    });
  };

export { diffDate }
