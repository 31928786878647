const intJud = () => {
  const cb = document.getElementById("account_int_jud")
  const jud = document.querySelector("#jud")
  const conventionnel = document.querySelector("#account_int_jud_type_conventionnel")
  const taux = document.querySelector("#taux-jud")
  cb.addEventListener('click',() => {
    if (cb.checked === true) {
      jud.style.display = "";
    } else {
      jud.style.display = "none";
      taux.style.display = "none";
    }
  });
  jud.addEventListener('click', () => {
    if (conventionnel.checked === true) {
      taux.style.display = "";
    } else {
      taux.style.display = "none";
    }
  });
}

export { intJud }