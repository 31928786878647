const keepConv = () => {
    const conv = document.querySelectorAll('fieldset > .form-check input')[0];
    const pct = document.querySelector("#taux-conv");
    const int_jud = document.querySelector("#account_int_jud");
    const jud = document.querySelector("#jud");
    const jud_conv = document.querySelectorAll("#jud fieldset > .form-check input")[0];
    const taux_jud = document.getElementById("taux-jud");
    if (conv.checked === true) {
      pct.style.display = "";
    } ;
    if (int_jud.checked === true) {
      jud.style.display = "";
    };
    if (jud_conv.checked === true) {
      taux_jud.style.display = "";
    };
};

export {keepConv}